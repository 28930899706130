<template>
  <div>
    <v-card class="mx-auto mb-4" outlined>
      <v-card-title>
        <v-icon>
          {{ icons.clock }}
        </v-icon>
        <span class="ml-2">
          Business Hours
        </span>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <th v-for="(v, i) in dayLabels" v-bind:key="i" class="text-left">
                {{ dayLabels[i] }}
              </th>
            </thead>
            <tbody>
              <tr>
                <td v-for="(day, x) in dayLabels" v-bind:key="x" valign="top">
                  <span v-for="(hr, i) in venueHours[x]" v-bind:key="i">
                    {{ hr.from }} - {{ hr.to }} <br />
                  </span>
                  <v-chip
                    x-small
                    color="red darken-2"
                    dark
                    v-if="venueHours[x].length < 1"
                  >
                    Closed
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiClockOutline } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        clock: mdiClockOutline
      },
      dayLabels: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
    }
  },
  props: {
    venue: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    venueHours() {
      return this.venue ? this.venue.venueHours() : []
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped></style>

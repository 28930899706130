<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Staff Information
          </div>
          <v-list-item-title v-if="venue.staff" class="headline mb-1">
            {{ venue.staff.email }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="venue.staff">
            {{ venue.staff.phone_number }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="primary"
          :to="{ name: 'user.details', params: { id: venue.staff.id } }"
        >
          View Profile
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    venue: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div v-if="venue">
      <v-app-bar flat color="transparent">
        <app-bar-nav-icon />
        <v-toolbar-title class="subtitle-1 d-flex align-center">
          <router-link class="text-decoration--none" :to="{ name: 'venues' }"
            >Venues</router-link
          >
          <v-icon small class="mx-2"> {{ icons.arrow }} </v-icon>
          {{ venue.name }}
        </v-toolbar-title>
      </v-app-bar>
      <v-row>
        <v-col>
          <v-img
            height="250"
            :src="coverImage"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="pa-0">
              <v-list-item three-line>
                <v-list-item-avatar class="avatar-bordered--white" v-if="venue">
                  <v-skeleton-loader
                    type="avatar"
                    :loading="loading"
                    class="grey"
                  >
                    <v-avatar color="primary" v-if="venue.venue_photos[0]">
                      <v-img :src="venue.venue_photos[0].thumb_url" />
                    </v-avatar>
                    <v-avatar v-else color="primary" class="white--text">{{
                      venue.initial
                    }}</v-avatar>
                  </v-skeleton-loader>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-skeleton-loader
                    type="sentences"
                    :loading="loading"
                    max-width="300px"
                  >
                    <v-list-item-title
                      class="text-h5 font-weight-medium white--text"
                    >
                      {{ venue.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2 white--text">
                      {{ venue.address }}
                    </v-list-item-subtitle>
                    <v-list-item-content
                      class="text-body-2 font-weight-light white--text"
                    >
                      {{ venue.price_range }} ● {{ venue.type.name }}
                      <div class="d-flex">
                        <div
                          v-for="tag in venue.tags"
                          :key="tag.id"
                          class="px-4 mr-2 py-1 darken-4 primary text-no-wrap rounded-pill"
                        >
                          {{ tag.tag }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-skeleton-loader>
                </v-list-item-content>
                <v-list-item-content>
                  <div class="d-flex align-end my-6">
                    <div class="ml-auto">
                      <action-buttons
                        :item="venue"
                        color="primary darken-4"
                        @onDelete="deleteVenue"
                      ></action-buttons>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card-title>
          </v-img>
          <div class="my-0">
            <v-card class="mb-4" outlined>
              <v-card-text>
                <v-simple-table dense class="mb-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td
                          class="text-left font-weight-black text--primary"
                          width="200px"
                        >
                          <b> Google business link</b>
                        </td>
                        <td class="text-left">
                          {{ venue.google_business_link }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left font-weight-black text--primary">
                          <b>Phone number</b>
                        </td>
                        <td class="text-left">{{ venue.phone_number }}</td>
                      </tr>
                      <tr>
                        <td class="text-left font-weight-black text--primary">
                          <b>Description</b>
                        </td>
                        <td class="text-left">{{ venue.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
            <business-hours :venue="venue"></business-hours>
          </div>
          <staff-information :venue="venue" />
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import Venue from '@/models/Venue'
import { mdiChevronRight } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ActionButtons from '@/views/Home/Venues/actionButtons'
import StaffInformation from './staff-information.vue'
import BusinessHours from './business-hours.vue'

import Form from '@/utils/form'

export default {
  name: 'VenueDetails',
  components: {
    AppBarNavIcon,
    ActionButtons,
    StaffInformation,
    BusinessHours
  },
  data() {
    return {
      icons: {
        arrow: mdiChevronRight
      },
      loading: false,
      deleteLoading: false,
      deleteDialog: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  computed: {
    ...mapState({
      venue: state => state.venue.venueDetails
    }),
    coverImage() {
      if (this.venue) return this.venue.coverImage()
      return ''
    }
  },

  methods: {
    ...mapActions({
      getVenueDetails: 'venue/getVenueDetails'
    }),

    ...mapMutations({
      clearVenueDetails: 'venue/clearVenueDetails'
    }),

    async getVenue() {
      this.loading = true
      await this.getVenueDetails(this.$route.params.id)
      this.form = new Form(this.venue)
      this.loading = false
    },

    async deleteVenue() {
      this.showSnackbar('Deleting venue...')
      const venue = new Venue(this.venue)
      await venue.delete().then(() => {
        this.form.$busy = false
        this.deleteDialog = false
        this.$router.replace('/venues')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  created() {
    this.getVenue()
  },

  destroyed() {
    this.clearVenueDetails()
  },

  watch: {
    $route() {
      this.getVenue()
    }
  }
}
</script>
